import helpers from "@/helpers";
import { PartecipanteItem } from "@/models/ricercaGaranzie/partecipanteItem";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import apiFile from "@/services/apiFile";
import { Component } from "vue-property-decorator";
import gridComponentBase from "../components/grid/gridComponentBase.vue";
import { Filter } from "@/models/grid/filter";

const _ENDPONT = api.endpoint.MY_OVERINS.ELENCO_ADESIONI_NETWORK;

@Component({})
export default class AcquistiNetworkPage extends gridComponentBase {
	distributori: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{
				field: "distributoreID",
				title: "Distributore",
				format: "{0:n0}",
				sortBy: "distributore",
				headerCell: "myHeaderCellTemplate",
				itemsForFilter: this.distributori,
				filterType: "select",
				values: this.distributori,
				export: true
			},
			{ field: "utente", title: "Utente", width: 130, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "numeroCompleto", title: "Adesione", width: 130, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "pacchetto", title: this.$i18n.t("generico.prodotto").toString(), headerCell: "myHeaderCellTemplate", export: true },
			{ field: "numeroPartecipanti", title: "Num Partecipanti", width: 100, headerCell: "myHeaderCellTemplate", type: "numeric-int", headerType: "numeric-int", export: true },
			{ field: "dataInserimento", title: "Data Acquisto", format: "{0:dd/MM/yyyy}", width: 150, headerCell: "myHeaderCellTemplate", type: "date", headerType: "daterange", export: true, exportRenderFunction: this.exportRenderDate },
			{ field: "fineViaggio", title: "Fine", format: "{0:dd/MM/yyyy}", width: 120, headerCell: "myHeaderCellTemplate", type: "renderfunction", headerType: "daterange", export: true, renderFunction: this.renderFunctionNoTimeZoneDate, exportRenderFunction: this.exportRenderDate },
			{ field: "importoViaggio", title: "Importo", format: "{0:n2}", width: 90, headerCell: "myHeaderCellTemplate", headerType: "numeric", export: true, type: "renderfunction", renderFunction: this.renderCellImportoViaggio, exportRenderFunction: this.renderCellImportoViaggio },
			{ field: "riferimentoPratica", title: "Riferimento", width: 130, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "totalePremio", title: "Premio Lordo", format: "{0:n2}", width: 80, headerCell: "myHeaderCellTemplate", type: "numeric", headerType: "numeric", export: true },
			{ field: "totalePremioNetto", title: "Premio Netto Imposte", format: "{0:n2}", width: 120, headerCell: "myHeaderCellTemplate", type: "numeric", headerType: "numeric", export: true },
			{ field: "commissioni", title: "Comm.", hidden: false, format: "{0:n2}", width: 80, headerCell: "myHeaderCellTemplate", type: "numeric", headerType: "numeric", export: true },
			{ field: "commissioniNetwork", title: "Comm. Net.", hidden: false, format: "{0:n2}", width: 120, headerCell: "myHeaderCellTemplate", type: "numeric", headerType: "numeric", export: true }
		];
	}

	get columnsMobile(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{
				field: "distributoreID",
				title: "Distributore",
				format: "{0:n0}",
				sortBy: "distributore",
				headerCell: "myHeaderCellTemplate",
				itemsForFilter: this.distributori,
				filterType: "select",
				values: this.distributori,
				export: true
			},
			{ field: "utente", title: "Utente", width: 130, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "numeroCompleto", title: "Adesione", width: 130, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "pacchetto", title: this.$i18n.t("generico.prodotto").toString(), headerCell: "myHeaderCellTemplate", export: true, width: 150 },
			{ field: "numeroPartecipanti", title: "Num Partecipanti", width: 100, headerCell: "myHeaderCellTemplate", type: "numeric-int", headerType: "numeric-int", export: true },
			{ field: "dataInserimento", title: "Data Acquisto", format: "{0:dd/MM/yyyy}", width: 150, headerCell: "myHeaderCellTemplate", type: "date", headerType: "daterange", export: true, exportRenderFunction: this.exportRenderDate },
			{ field: "fineViaggio", title: "Fine", format: "{0:dd/MM/yyyy}", width: 120, headerCell: "myHeaderCellTemplate", type: "renderfunction", headerType: "daterange", export: true, renderFunction: this.renderFunctionNoTimeZoneDate, exportRenderFunction: this.exportRenderDate },
			{ field: "importoViaggio", title: "Importo", format: "{0:n2}", width: 90, headerCell: "myHeaderCellTemplate", headerType: "numeric", export: true, type: "renderfunction", renderFunction: this.renderCellImportoViaggio, exportRenderFunction: this.renderCellImportoViaggio },
			{ field: "riferimentoPratica", title: "Riferimento", width: 130, headerCell: "myHeaderCellTemplate", export: true },
			{ field: "totalePremio", title: "Premio Lordo", format: "{0:n2}", width: 80, headerCell: "myHeaderCellTemplate", type: "numeric", headerType: "numeric", export: true },
			{ field: "totalePremioNetto", title: "Premio Netto Imposte", format: "{0:n2}", width: 120, headerCell: "myHeaderCellTemplate", type: "numeric", headerType: "numeric", export: true },
			{ field: "commissioni", title: "Comm.", hidden: false, format: "{0:n2}", width: 80, headerCell: "myHeaderCellTemplate", type: "numeric", headerType: "numeric", export: true },
			{ field: "commissioniNetwork", title: "Comm. Net.", hidden: false, format: "{0:n2}", width: 120, headerCell: "myHeaderCellTemplate", type: "numeric", headerType: "numeric", export: true }
		];
	}


	created() {
		var self = this;
		var p = [
			api.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI).then(res => (self.distributori = res)),
		];

		if (!(this.isAppMobile == false)) {
			this.pageable["buttonCount"] = 3;
			this.pageable["info"] = false;
			this.pageable["pageSizes"] = undefined;
		}

		Promise.all([p])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	getData() {
		this.getGridData(_ENDPONT);
	}

	getPartecipanti(data: string) {
		var array = JSON.parse(data) as PartecipanteItem[];
		let partecipanti: string[] = [];
		if (array && array.length > 0) {
			partecipanti = array.map(function(item) {
				return `${item.Cognome} ${item.Nome}`;
			});
		}
		return partecipanti;
	}

	downloadAdesioneFile(guid) {
		var self = this;
		var url = `adesione/${guid}`;
		var nomeFile = "CertificatoAssicurativo.pdf";
		apiFile
			.downloadFile(url, nomeFile)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/pdf" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = nomeFile;
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}

	downloadFileImmaginePartecipanti(path) {
		var self = this;
		//var url = `file?name=${path}`;
		var url = `imgPartecipanti?name=${path}`;
		var nomeFile = path.substring(path.lastIndexOf('/') + 1);
		apiFile
			.downloadFile(url, "")
			.then(response => {
				const blob = new Blob([response.data], { type: response.headers['content-type'] });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = nomeFile;
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}

	getTestoTipoApplicazione(data: any){
		return data.secondoImporto ? helpers.getTipoApplicazioneLabelAlloggi( data.tipoApplicazione) : "Alloggi";
	}

	getUlterioriDatiValore(item){
		return helpers.getUlterioriDatiValore(item);
	}

	getDatiAggiuntivi(item) {
		return item.datiAggiuntivi ? JSON.parse(atob(item.datiAggiuntivi)) : null
	}

	renderCellImportoViaggio(data: number, a: any) {
		if(a.secondoImporto && a.secondoImporto > 0)
			return kendo.toString(a.secondoImporto, "n2")
		return kendo.toString(data || 0, "n2");
	}

	showfilterDialog: boolean = false;

	onApplyFiltersMobile(filtersMobile: Filter) {
		this.filter = filtersMobile;
		this.onFilterMobileApply();
		this.onCloseDialogMessage();
	}
	onRemoveAllFilters() {
		this.clearSortAndFilter();
		//this.onCloseDialogMessage();
	}

	onCloseDialogMessage() {
		this.showfilterDialog = false;
	}

	onOpenFilterDialog() {
		this.showfilterDialog = true;
	}
}
